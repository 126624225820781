import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    accessToken: null
  }),
  getters: {
    isAdmin(state) {
      return state.user && state.user.level === 10;
    },
    userRole(state) {
      if(!state.user) {
        return 'guest';
      } else {
        return state.user.level === 10 ? 'admin' : 'user';
      }
    }
  },
  actions: {
    updateUserInfo(user) {
      this.user = user;
    },
    updateAccessToken(accessToken) {
      this.accessToken = accessToken;
    },
  },
})