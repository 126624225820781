import Vue from "vue"
import VueRouter from "vue-router";
import App from "./App.vue"
import router from "./router"
import api from "./services/api"
import store from "./stores/index"
import formatter from "./services/formatter"
import infiniteScroll from "vue-infinite-scroll"
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.config.productionTip = false

// this.$api.
Object.defineProperty(Vue.prototype, "$api", { value: api })
// this.$store.
Object.defineProperty(Vue.prototype, "$store", { value: store })
// this.$formatter.
Object.defineProperty(Vue.prototype, "$formatter", { value: formatter })


Vue.use(infiniteScroll)
Vue.use(PiniaVuePlugin)

Vue.use(VueRouter);

const pinia = createPinia()
const app = new Vue({
  pinia,
  router,
  render: h => h(App),
})
app.$mount('#app')  



