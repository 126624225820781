const options = require("@/services/helper");

const generateRequestOptions = options.default.generateRequestOptions; 
const api_url = options.default.endpoint;


module.exports = {
	async get(user_id) {
		const res = await fetch(api_url+"notification/u/"+encodeURIComponent(user_id), 
			generateRequestOptions("GET"));
		return res.json();
	},

	async setSeen(id) {
		const res = await fetch(api_url+"notification/seen", 
			generateRequestOptions("PUT", {id: id}));
		return res.json();
	},

	async new(user_id, text, data) {
		const res = await fetch(api_url+"notification/", 
		generateRequestOptions("POST", {
			id_user: user_id,
			text: text,
			data: data
		}));
		return res.json();
	}
};