export default {
  isAuthenticated() {
    return localStorage.getItem("authenticated") === "true"
  },
  // user id
  getId() {
    let id = localStorage.getItem("id")
    return id !== null ? parseInt(id) : false
  },
  getUsername() {
    let username = localStorage.getItem("username")
    return username !== null ? username : false
  },
  setName(name) {
    localStorage.setItem("name", name)
  },

  setUsername(username) {
    // localStorage.removeItem("username");
    // localStorage.removeItem("nickname");
    localStorage.setItem("username", username);
    localStorage.setItem("nickname", username);
  },
  setAdminPostName(name) {
    localStorage.setItem("adminPostName", name)
  },
  getAdminPostName() {
    return localStorage.getItem("adminPostName")
  },
  getName() {
    let name = localStorage.getItem("name")
    return name !== null ? name : false
  },
  getNickname() {
    let nickname = localStorage.getItem("nickname")
    return nickname !== null ? nickname : false
  },
  getImage() {
    let image = localStorage.getItem("image")
    return image !== null && image !== "null" ? image : false
  },
  getAdminImg() {
    let adminImg = localStorage.getItem("admin-image")
    return adminImg !== null && adminImg !== "null" && adminImg !== undefined
      ? adminImg
      : false
  },
  adminImgExists() {
    let adminImg = localStorage.getItem("admin-image")
    return adminImg !== null &&
      adminImg !== "null" &&
      adminImg !== undefined &&
      adminImg !== ""
      ? true
      : false
  },
  setImage(url) {
    localStorage.setItem("image", url)
  },
  setAdminImage(url) {
    localStorage.setItem("admin-image", url)
  },
  getCoverImage() {
    let image = localStorage.getItem("cover_image")
    return image !== null && image !== "null" ? image : false
  },
  setCoverImage(url) {
    localStorage.setItem("cover_image", url)
  },
  getSubscribed() {
    let subscribed = localStorage.getItem("subscribed")
    return subscribed !== null ? JSON.parse(subscribed) : false
  },
  setSubscribed(subscribed) {
    if (Array.isArray(subscribed))
      localStorage.setItem("subscribed", JSON.stringify(subscribed))
  },
  setInitSub(allTags) {
      const initTag = [];
      for(let i of allTags) {
        if(i.name === "E-Sport News" || i.name === "Gaming News" || i.name === "Gaming news" || i.name === "E-Sport news") {
          initTag.push(i)
        } 
      }
      this.setSubscribed(initTag);
  },
  updateSubscribed(tagId, name, createNew = false) {
    let subscribed = this.getSubscribed()

    if (subscribed === false && createNew) {
      subscribed = []
    }

    if (subscribed !== false && Array.isArray(subscribed)) {
      let index = subscribed.findIndex((el) => el.id_tag === tagId)
      if (index === -1) subscribed.push({ id_tag: tagId, name: name })
      else subscribed.splice(index, 1)

      this.setSubscribed(subscribed)
    }
  },
  // remove tag subscription
  removeSubscribed(tagId) {
    let subscribed = this.getSubscribed()

    if (subscribed !== false && Array.isArray(subscribed)) {
      let index = subscribed.findIndex((el) => el.id_tag === tagId)
      if (index !== -1) {
        subscribed.splice(index, 1)
      }
      this.setSubscribed(subscribed)
    }
  },
  cleanUserSubscriptions() {
    localStorage.removeItem("subscribed")
  },

  updateSettings(newSettings) {
    if ("cover_img_url" in newSettings)
      this.setCoverImage(newSettings["cover_img_url"])
  },
  /**
   * Set the login redirect if sent to different page.
   * @param url
   */
  setLoginRedirect(url) {
    localStorage.setItem("login_redirect", url)
  },
  /**
   * Return the login redirect url.
   * @returns {string|boolean}
   */
  getLoginRedirect() {
    let redirect = localStorage.getItem("login_redirect")
    localStorage.removeItem("login_redirect")
    return redirect !== null && redirect !== "null" ? redirect : false
  },

  // Admin section
  getLevel() {
    let level = localStorage.getItem("level")
    return level !== null ? parseInt(level) : false
  },
  isAdmin() {
    let level = this.getLevel()
    return level === 10
  },
  setAllowStats(isAllowed) {
    if (isAllowed) {
      localStorage.setItem("allowStats", "vfuidzfdzadsad")
    } else {
      localStorage.removeItem("allowStats")
    }
  },
  isStats() {
    let statsState = localStorage.getItem("allowStats")
    if (statsState === "vfuidzfdzadsad") {
      return true
    } else {
      return false
    }
  },
  setColorMode(mode) {
    mode = mode === "light" || mode === "dark" ? mode : "dark"
    localStorage.setItem("colorMode", mode)
  },
  getColorMode() {
    let mode = localStorage.getItem("colorMode")
    return mode !== null ? mode : "dark"
  },
  /**
   * Getter and setter of cookies.
   */
  setCookies() {
    localStorage.setItem("cookiesAllowed", "true")
  },
  getCookies() {
    let allowed = localStorage.getItem("cookiesAllowed")
    return allowed !== null && allowed !== "false"
  },
}
