<template>
  <div class="container">
    <div class="alert" v-if="errorMsg.length > 0">
      <h1>Alert</h1>
      <h3>{{ errorMsg }}</h3>
    </div>

    <form @submit.prevent="handleSubmit" class="form shadow">
      <h1>{{ title }}</h1>

      <input
        :type="usernameFieldtype"
        required
        placeholder="Username"
        v-model="model.username"
      />
      <input
        type="email"
        autocomplete="email"
        placeholder="E-mail"
        v-model="model.email"
        required
      />
      <input 
        type="password" 
        autocomplete="new-password"
        placeholder="Password" 
        v-model="model.password" />

      <div v-if="showCheckbox()" class="checkbox-container">
        <Checkbox 
          class="checkbox"
          title="Agree with terms and conditions"
          @onCheckboxStateChange="onCheckboxStateChange($event)"/>
        <span class="checkbox--text">I agree to &nbsp;<router-link to="/terms-and-conditions">terms & conditions</router-link>.</span>
      </div>

      <input type="submit" :value="title" />
      <h5 v-if="registerMode" class="disclaimer-text">
        Greeting gamers! The website is beta phase and so we are NOT using any
        cookies to personalise content and ads. Paid ads are shown equally to
        all users. Content generated in the main wall is created by individual
        users with TAG selection.<br /><br />All existing users will be
        presented with the privacy option in the settings subpage after we move
        to the new version.
      </h5>
      <h5 v-if="!registerMode" class="disclaimer-text">
        The change to a higher password standard is being made to improve the security of users' accounts and data. 
        By requiring a minimum length of 6 characters, mix of uppercase and lowercase letters, as well as a number, 
        the site is making it more difficult for malicious actors to guess or crack passwords.
        Click "Forgot password" to step into the new world! </h5>

        <router-link to="/forgot-password" v-if="!registerMode" class="forgot-password">
        Forgot password?
        </router-link>
    </form>

    <a @click="registerMode = !registerMode">{{ registerButton }}</a>
  </div>
</template>

<script>
//Store
import { useUserStore } from "@/stores/UserStore";
import { mapWritableState, mapStores } from "pinia";
import { useUserTagStore } from "@/stores/tagsStore.js";
// Components
import Checkbox from "./Checkbox.vue";

export default {
  name: "Login",
  components: { Checkbox },
  data() {
    return {
      model: {
        username: "",
        email: "",
        password: "",
        hasRead: false
      },
      registerMode: true,
      displayCheckbox: false,
      errorMsg: "",
    }
  },
  mounted() {
    if(this.$route.query.view) {
      this.registerMode = false;
    }
  },
  methods: {
    showCheckbox() {
      if(this.registerMode) {
        return true;
      } else if(this.displayCheckbox) {
        return true;
      } else {
        return false;
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      
      if (this.registerMode) {
        if(this.validateEmailAndSetErrorMsg() && this.isPasswordValidAndSetErrorMsg() && this.validateUsernameAndSetErrorMsg() && this.hasReadValidAndSetErrorMsg()) {
          // Shrani značko, kateri bo sledil nov registriran uporabnik.
          await this.$api.register(this.model, true)
            .then(response => {
              // Handle error
              if(!response.success) {
                this.errorMsg = response.error.message;
                return;
              }

              const { accessToken, user } = response; 
              this.userStore.user = user;
              this.userStore.updateAccessToken(accessToken);
              this.$store.setSubscribed([{"id_tag":1,"name":"E-Sport News"}]);
              this.$router.push({ path: "/setup" })
            })
        }        
      } else {
        if(this.validateEmailAndSetErrorMsg() && this.isPasswordValidAndSetErrorMsg()) {
          this.login();
        }
      }
    },
    validateUsernameAndSetErrorMsg() {
      const trimedUsername = this.model.username.trim();
      const re = /^[a-zA-Z0-9]+[a-zA-Z0-9-]+[a-zA-Z0-9]+$/;
      if (typeof trimedUsername !== 'string') return false;
      if (trimedUsername.length < 3 || trimedUsername.length > 25) {
        this.errorMsg = "Username length must be between 3 and 25 characters.";
        return false
      };
      if (!trimedUsername.trim().length) {
        this.errorMsg = "Username is a required field.";
        return false
      };
      if (!(re.test(String(trimedUsername).toLowerCase()))) {
        this.errorMsg = "Username can only contain letters, numbers and hyphens('-').";
        return false
      }

      this.errorMsg = "";
      return true;
    },
    validateEmailAndSetErrorMsg() {
      const trimedEmail = this.model.email.trim();
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (typeof trimedEmail !== 'string') return false;
      if (trimedEmail.length < 8 || trimedEmail.length > 255) {
        this.errorMsg = "E-mail length must be between 8 and 255 characters.";
        return false
      };
      if (!trimedEmail.trim().length) {
        this.errorMsg = "E-mail is a required field.";
        return false
      };

      if (!(re.test(String(trimedEmail).toLowerCase()))) {
        this.errorMsg = "Please enter the correct e-mail address format.";
        return false
      }

      this.errorMsg = "";
      return true;
    },
    isPasswordValidAndSetErrorMsg() {
      const trimedPassword = this.model.password.trim();
      if (typeof trimedPassword !== 'string') return false;
      if (trimedPassword.length < 6 || trimedPassword.length > 50) {
        this.errorMsg = "Password length must be between 6 and 50 characters.";
        return false
      };
      if (!trimedPassword.trim().length) {
        this.errorMsg = "Password is a required field.";
        return false
      };
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$:%^&*()_+]*$/.test(trimedPassword)) {
        if(this.registerMode) {
          this.errorMsg = "Password must contain at least one lowercase and uppercase letter and a number. Password can contain only this special characters !@#$:%^&*()_";
        } else {
          this.errorMsg = "Email/password not valid.";
        }
        return false
      };
      this.errorMsg = "";
      return true;
    },
    hasReadValidAndSetErrorMsg() {
      if(!this.model.hasRead) {
        this.errorMsg = "You must agree to the terms and conditions.";
        return false;
      }

      this.errorMsg = "";
      return true;
    },
    onCheckboxStateChange(state) {
      this.model.hasRead = state;
    },
    async login() {
      await this.$api.login(this.model)
        .then(data => {
          if(!data.success) {
            this.errorMsg = data.error.message;
            return;
          }

          if(!data.hasRead) {
            this.displayCheckbox = true;
            this.errorMsg = "Efective from 1 March, 2023 all users need to agree with terms and conditions.";
            return;
          }
          const { accessToken, user, subscribedTags } = data;
          this.userStore.user = user;
          this.userStore.updateAccessToken(accessToken);
          this.$store.setSubscribed(subscribedTags);

          this.$router.push({ path: "/" });
        })
    }

  },
  computed: {
    ...mapWritableState(useUserTagStore, ['hasTags']),
    ...mapStores(useUserStore),
    title() {
      return this.registerMode ? "REGISTER" : "LOGIN"
    },
    usernameFieldtype() {
      return this.registerMode
        ? "text"
        : "hidden"
    },
    registerButton() {
      return this.registerMode
        ? "Already have an account? Log in"
        : "Not a member yet? Click here to register."
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  max-width: 500px;
  @include center;

  .form {
    @include center;
    padding: 5%;
    margin-bottom: 15px;

    @include box-shadow;
    background-color: #242326;
    text-align: center;
    border: none;
    border-radius: 15px;

    h1 {
      @extend .noselect;
      margin-top: 0;
      margin-bottom: 20px;

      color: #f1f1fe;
      font-size: 1.3em;
    }

    .disclaimer-text {
      color: #fff;
    }

    .forgot-password {
      margin-top: 0 !important;
      padding-bottom: 0 !important;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: var(--orange);
        font-weight: 600;
      }
    }

    // Inputs
    input {
      display: block;
      box-sizing: content-box;
      width: 90%;
      max-width: 90%;
      @include center;
      margin-top: 15px;

      border: none;
      border-radius: 15px;
      background-color: #f1f1fe;
      color: #090909;

      @include placeholder {
        color: #8b8a92;
        font-size: 1.1em;
      }
      animation: fadeIn 0.3s, popup 0.4s;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"] {
      padding: 15px;

      &:focus {
        background-color: #f1f1fe;
      }
    }
    input[type="submit"] {
      @extend .pointer;
      @extend .noselect;
      @include gradient;

      width: 30%;
      min-width: 6em;
      padding: 15px;

      color: #ffffff;
      font-size: 1em;

      &:hover {
        @include popup(1.04);
      }
    }

    .checkbox-container {
      display: flex;
      align-items: flex-end;
      .checkbox {
        margin-left: 10px;
        margin-top: 10px;
        top: -2px;

        &--text {
          display: flex;
          align-items: center;
          color: white;
          margin-left: 5px;

          a {
            padding: 0;
            color: var(--orange)
          }
        }
      }
    }
  }

  // Controls
  a,
  button {
    @extend .pointer;
    @extend .noselect;

    display: block;
    @include center;
    padding: 10px;
    border: none;
    border-radius: 3px;
    color: #f1f1fe;

    &:hover {
      color: #f1f1fe;
    }
  }

  .alert {
    @include center;
    @include box-shadow;

    animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

    position: fixed;
    top: 10%;
    right: 10%;

    background-color: var(--orange);
    border-radius: 15px;
    padding: 10px;

    h1 {
      color: #242326;
      font-size: 1.5em;
    }
    h3 {
      color: #242326;
      font-size: 1.2em;
    }
  }
}
</style>
