import { ref } from 'vue';
import VueRouter from "vue-router";
import store from "@/stores/index";
import api from "@/services/api";
import { useUserStore } from '@/stores/UserStore';
// Components
import SignIn from "@/views/SignIn";

const refreshInterval = ref(null);

function refreshToken(userStore, next) {
  if (refreshInterval.value) {
    clearInterval(refreshInterval.value);
  }

  refreshInterval.value = setInterval(async () => {
    // Perform the silent token refresh here
    await api.verifyRefreshToken()
      .then(data => {
        if(data.error) {
          userStore.$reset();
          next({ name: "Landing Feed" });
        } else {
          const { accessToken, user } = data;
          userStore.updateUserInfo(user);
          userStore.updateAccessToken(accessToken);
        }
      });
  }, 900 * 1000); // Executes interval every 15 min
}

// Fix for NavigationDuplicated exception, Redundant navigation to current location
// Prints any other vue router exceptions to console
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") console.error(err);
  });
};

const routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword"),
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword"),
    beforeEnter: async (to, from, next) => {
      if(to.query.token !== '' && to.query.token !== undefined) {
        // Check if token exists and if it is valid
        const otpExists = decodeURIComponent(to.query.token);
        const res = await api.allowReset(otpExists);

        if(res.success) {
          next();
        } else {
          next({ path: `/sign-in` });
        }
      }
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import("@/views/TermsAndConditions")
  },
  //#region Routes for guests only
  {
    path: "/landing",
    name: "Landing Feed",
    component: () => import("@/views/LandingPage"),
    meta: { requiresGuest: true }
  },
  {
    path: "/post/:id",
    name: "Opened Post",
    component: () => import("@/views/OpenPostView"),
  },
  {
    path: "/calendar/open",
    name: "CalendarOpen",
    component: () => import("@/views/CalendarOpen"),
  },
  //#endregion
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    meta: { requiresAuth: true }
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("@/views/Notifications"),
    meta: { requiresAuth: true }
  },
  {
    path: "/tags",
    name: "Tags",
    component: () => import("@/views/Tags"),
    meta: { requiresAuth: true }
  },
  {
    path: "/tag/:tagName",
    name: "IsolatedTag",
    component: () => import("@/views/Tag"),
    props: (route) =>  ({ tagName: route.params.tagName }),
    meta: { requiresAuth: true }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("@/views/Calendar"),
    meta: { requiresAuth: true }
  },
  {
    path: "/calendar/:tagName",
    name: "TagCalendar",
    component: () => import("@/views/TagCalendar"),
    props: (route) =>  ({ tagName: route.params.tagName }),
    meta: { requiresAuth: true }
  },
  {
    path: "/group/:id(\\d+)",
    name: "Group",
    component: () => import("@/views/Group"),
    children: [
      {
        path: "/group/:id(\\d+)/tab/:tabId",
        component: () => import("@/views/TabFeed"),
        meta: { requiresAuth: true }
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "/user/Nobody",
    name: "ProfilePageNobody",
    component: () => import("@/views/UserDeleted"),
    meta: { requiresAuth: true }
  },
  {
    path: "/user/:userIdentity",
    name: "ProfilePage",
    component: () => import("@/views/User"),
    meta: { requiresAuth: true }
  },
  {
    path: "/setup",
    name: "ProfileSetup",
    component: () => import("@/views/ProfileSetup"),
    meta: { requiresAuth: true }
  },
  {
    path: "/group/settings/:group",
    name: "GroupSetup",
    component: () => import("@/views/GroupSetup"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const userStore = useUserStore();
      api.getGroupInfo(to.params.group, ["info"], userStore.accessToken)
        .then((response) => {
          if (userStore.user.isAdmin || userStore.user.id === response.info.id_creator) next();
          else next({ path: `/group/${to.params.group}` });
      });
    },
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import("@/views/Groups"),
    meta: { requiresAuth: true }
  },
  {
    path: "/image/:image",
    name: "Image",
    component: () => import("@/views/Image"),
    meta: { requiresAuth: true }
  },
  {
    path: "/friends",
    name: "Friends",
    component: () => import("@/views/Friends"),
    meta: { requiresAuth: true }
  },

  // Admin
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/Admin"),
    beforeEnter: (to, from, next) => {
      const userStore = useUserStore();

      if (userStore.isAdmin) next();
      else next({ path: "/" });
    },
    meta: { requiresAuth: true }
  },

  {
    path: "/LoginStats",
    name: "LoginStats",
    component: () => import("@/views/LoginStats"),
    beforeEnter: (to, from, next) => {
      const userStore = useUserStore();

      if (userStore.isAdmin) next();
      else next({ path: "/" });
    },
    meta: { requiresAuth: true }
  },

  {
    path: "/eFAN-Statistics",
    name: "eFAN-Statistics",
    component: () => import("@/views/Statistics"),
    beforeEnter: (to, from, next) => {
      const userStore = useUserStore();

      if (store.isStats() && userStore.isAdmin) next();
      else next({ path: "/" });
    },
    meta: { requiresAuth: true }
  },

  // Wip ,404 pages and This user is deleted page
  {
    path: "/UserDeleted",
    name: "UserDeleted",
    component: () => import("@/views/UserDeleted"),
    meta: { requiresAuth: true }
  },
  {
    path: "/comingsoon",
    name: "ComingSoon",
    component: () => import("@/views/ComingSoon"),
  },
  { path: "/wip", name: "Wip", component: () => import("@/components/Wip") },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/components/NotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route guard
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const requiresAuth = to.matched.some(record  => record.meta.requiresAuth);
  // How can i check if the user has a certain query param?
  const referLinkLabel = to.query.fan;
  if (referLinkLabel) {
    await api.logReferLinkVisitor(referLinkLabel);
  }

  // Check only once for a refresh token
  if(!userStore.user) {
    await api.verifyRefreshToken()
    .then(data => {
      if(data.error) {
        userStore.$reset();
        
        if(requiresAuth) {
          next({ name: "Landing Feed" });
        } else {
          next();
        }
      } else {
        const { accessToken, user } = data;
        userStore.updateUserInfo(user);
        userStore.updateAccessToken(accessToken);

        refreshToken(userStore, next); // Sets the interval

        if(to.path.includes("sign-in") || to.path.includes("landing") || to.path.includes("calendar/open")) {
          next({ name: "Home" });
        } else {    
          next();
        }
      }
    });
  } else {
    refreshToken(userStore, next); // Sets the interval - for silent refresh

    if(to.path.includes("sign-in") || to.path.includes("landing") || to.path.includes("calendar/open")) {
      next({ name: "Home" });
    } else {
      next();
    }
  }
});

export default router;
