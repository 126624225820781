import * as options from "@/options.json"

var endpoint =
  options.developmentMode === true
    ? options.development.endpoint
    : options.production.endpoint

export default {
  generateFetch(url, options = {}, token = null, isFormData = false) {
    const headers = {
      'Authorization': `Bearer ${token}`,
      ...options.headers,
    };

    if(!isFormData) {
      headers['Content-Type'] = 'application/json'
    }

    return fetch(url, {
      credentials: 'include',
      headers,
      ...options,
    })
  },

  generateRequestOptions(method, data) {
    let headers
    if (method === "POST" || method === "PUT") {
      headers = { "Content-Type": "application/json" }
    }
    data = typeof data === "object" ? JSON.stringify(data) : data

    return {
      method: method,
      headers: headers,
      body: data,
    }
  },

  endpoint: endpoint,
}
