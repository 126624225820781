<template>
    <div class="terms-and-conditions-container">
        <label for="terms-and-conditions" class="terms-and-conditions--label">
          <input type="checkbox"
            id="terms-and-conditions"
            class="terms-and-conditions--checkbox"
            :title="title"
            v-model="checked" />
            <span @click="onClick" :title="title" class="terms-and-conditions--placeholder"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        state: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Checkbox"
        }
    },
    created() {
        this.checked = this.state;
    },
    data() {
        return {
            checked: false
        }  
    },
    methods: {
        onClick() {
            this.$emit('onCheckboxStateChange', !this.checked)
        }
    }
}
</script>

<style lang="scss" scoped>
    .terms-and-conditions-container {
        display: flex;
        position: relative;

        .terms-and-conditions {
            &--label {
                margin-left: 7px;
                &:hover {
                    cursor: pointer;
                }
            }
            
            &--checkbox {
                visibility: hidden;
                &:active ~ span,
                &:checked ~ span {
                    background-color: var(--orange);
                }

                &:checked ~ span:after {
                    display: block;
                }
            }

            &--placeholder {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: white;
                border-radius: 5px;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;

                    left: 8px;
                    bottom: 5px;
                    width: 6px;
                    height: 12px;
                    border: solid white;
                    border-width: 0 4px 4px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }
</style>