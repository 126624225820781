<template>
  <div
    id="app"
    class="app"
    :class="{ dark: colorMode === 'dark', light: colorMode === 'light' }"
  >
    
    <router-view />
    <Cookie />   
  </div>
</template>

<script>
// Components
import Cookie from "@/components/Cookie";

export default {
  name: "App",
  components: { Cookie },
  data() {
    return {
      colorMode: this.$store.getColorMode(),
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/global.scss";

#app {
  min-height: 100vh !important;
  height: auto;
  background-color: var(--background);
}
</style>
