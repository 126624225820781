/*
	GUIDE:
	- function should receive already context checked parameters
	(eg. month is between 1 and 12), and should only check for
	correct data types
	- use fetch to send the request
	- use generateRequestOptions to generate the request options
	- return json {success: true/false, ...}
*/
import helper from "@/services/helper";
const api_url = helper.endpoint;
const generateRequestOptions = helper.generateRequestOptions;

const notification = require("./notification.js");

export default {
  //#region Auth 
  async login(model) {
    const response = await helper.generateFetch(`${api_url}auth/login`, {
      method: 'POST',
      body : JSON.stringify({
        email: model.email,
        password: model.password,
        hasRead: model.hasRead
      })
    })

    return response.json();
  },

  async register(model, returnCookie = false) {
    const response = await helper.generateFetch(`${api_url}auth/register`, {
      method: 'POST',
      body : JSON.stringify({
        username: model.username,
        email: model.email,
        password: model.password,
        hasRead: model.hasRead,
        cookie: returnCookie
      })
    })

    return response.json();
  },

  async logout(accessToken) {
    const response = await helper.generateFetch(`${api_url}auth/logout`, {
      method: 'DELETE'
    }, accessToken)

    return response.json();
  },

  async verifyRefreshToken() {
    const response = await helper.generateFetch(`${api_url}auth/refresh-token`, {
      method: 'POST',
    })

    return response.json();
  },
  //#endregion

  //#region User and User profile related apis
  async updateAndReturnUser(model, accessToken) {
    const response = await helper.generateFetch(`${api_url}new/user/settings`, {
      method: 'PATCH',
      body : JSON.stringify(model)
    }, accessToken)

    return response.json();
  },

  //#region User sugestions
  async getUserInfo(hash, accessToken) {
    const url = api_url + 'new/user?' + new URLSearchParams({ hash })

    const response = await helper.generateFetch(url, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  async getUserPosts(userId, limit, offset, accessToken) {
    const url = api_url + 'new/user/posts?' + new URLSearchParams({ userId, limit, offset })

    const response = await helper.generateFetch(url, {
      method: 'GET'
    }, accessToken)

    return response.json();
  },

  async getUserSuggestions(userId, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/u/${encodeURIComponent(userId)}/suggestions`, {
      method: 'GET'
    }, accessToken)

    return response.json();
  },

  async getPlatformUsers(userId, query, limit, offset, accessToken) {
    const url = api_url + `new/user/platform-users/?` + new URLSearchParams({ userId, limit, query, offset })

    const response = await helper.generateFetch(url, {
      method: 'GET'
    }, accessToken)

    return response.json();
  },

  /**
   * @summary Checks if a user is a member of the group. 
   */
  async isMember(id_user, id_group, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/isMember`, {
      method: 'POST',
      body : JSON.stringify({
        id_user,
        id_group,
      })
    }, accessToken)

    return response.json();
  },

  /**
   * @summary Set relationship between a member and a group. 
   */
  async joinGroup(id_user, id_group, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/join`, {
      method: 'POST',
      body : JSON.stringify({
        id_user,
        id_group,
      })
    }, accessToken)

    return response.json();
  },

  async getUserGroups(id_user, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/u/${encodeURIComponent(id_user)}/groups`, {
      method: 'GET',
    }, accessToken)

    return response.json();
  },

  /**
   * @returns A flag that is true if a user is following the provided user
   */
  async isFollowing(id_follower, id_user, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/isFollowing`, {
      method: 'POST',
      body : JSON.stringify({
        id_user,
        id_follower,
      })
    }, accessToken)

    return response.json();
  },

  async followUser(id_follower, id_user, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/follow`, {
      method: 'POST',
      body : JSON.stringify({
        id_user,
        id_follower,
      })
    }, accessToken)

    return response.json();
  },
  //#endregion
  
  //#region GET
  async getHash(userID, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/u/hash/${encodeURIComponent(userID)}`, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  async getFriends(userId, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/u/${encodeURIComponent(userId)}/friends`, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },
  //#endregion
  
  //#region POST
  async deleteUser(user_id, model, accessToken) {
    if (user_id > 1 && user_id !== undefined) {
      const response = await helper.generateFetch(`${api_url}user/delete/${encodeURIComponent(user_id)}`, {
        method: 'POST',
        body : JSON.stringify(model)
      }, accessToken)

      return response.json();
    }
  },
  //#endregion

  //#endregion

  //#region Groups
  //#region Get
  async getPublicGroups(id_user, accessToken) {
    const response = await helper.generateFetch(`${api_url}group/groups/${encodeURIComponent(id_user)}`, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  async getPublicGroupsForGuests() {
    const res = await fetch(
      api_url + "group/groups/open/0",
      generateRequestOptions("GET")
    );
    return res.json();
  },

  async getGroupInfo(groupId, filter, accessToken) {
    const response = await helper.generateFetch(`${api_url}group/${encodeURIComponent(groupId)}`, {
      method: 'POST',
      body: JSON.stringify({ list: filter})
    }, accessToken); 

    return response.json();
  },
  
  //#endregion
  //#region Post
  async insertGroup(name, description, private_group, id_user, accessToken) {
    const response = await helper.generateFetch(`${api_url}group`, {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        description: description,
        id_creator: id_user,
        private: private_group,
      })
    }, accessToken);

    return response.json();
  },

  async updateGroupSettings(newSettings, accessToken) {
    const response = await helper.generateFetch(`${api_url}group/settings`, {
      method: 'POST',
      body: JSON.stringify(newSettings)
    }, accessToken);

    return response.json();
  },

  /**
   * @param { Boolean} state True defines if a member should be set to moderator of a group
   */
  async updateUsersPermission(userId, groupId, state, accessToken) {
    const url = api_url + 'group/permissions?' + new URLSearchParams({ groupId, userId })

    const response = await helper.generateFetch(url, {
      method: 'POST',
      body: JSON.stringify({ givePermission: state })
    }, accessToken);

    return response.json();
  },
  //#endregion

  //#region Tabs
  //#region Get
  async getTab(tabId, accessToken) {
    const response = await helper.generateFetch(
      `${api_url}tab/${encodeURIComponent(tabId)}`, {
      method: 'GET',
    }, accessToken);

    return response.json();
  },

  async getTabs(groupId, accessToken) {
    const url = api_url + 'tab?' + new URLSearchParams({ idGroup: groupId })
    const response = await helper.generateFetch(url, {
      method: 'GET',
    }, accessToken);

    return response.json();
  },

  async getTabPosts(groupId, tabId, limit, offset, accessToken) {
    const url = `${api_url}group/tabPosts/${encodeURIComponent(groupId)}/${encodeURIComponent(tabId)}?` + new URLSearchParams({ limit, offset })

    const response = await helper.generateFetch(url, {
      method: 'GET',
    }, accessToken); 

    return response.json();
  },

  async getExposedTabPosts(groupId, tabId, accessToken) {
    const response = await helper.generateFetch(
      `${api_url}group/exposedTabPosts/${encodeURIComponent(groupId)}/${encodeURIComponent(tabId)}`, {
      method: 'GET',
    }, accessToken); 

    return response.json();
  },
  //#endregion
  //#region Post
  async insertTab(idGroup, name, isMain = false, writePermission = 'all', accessToken) {
    const url = api_url + 'tab?' + new URLSearchParams({ idGroup })
    const response = await helper.generateFetch(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        isMain,
        writePermission
      })
    }, accessToken);

    return response.json();
  },

  async editTab(editedTab, accessToken) {
    const response = await helper.generateFetch(`${api_url}group/editTab`, {
      method: 'POST',
      body: JSON.stringify({
        tabId: editedTab.tabId,
        tabName: editedTab.tabName,
      })
    }, accessToken);

    return response.json();
  },

  async deleteTab(tabId, accessToken) {
    const response = await helper.generateFetch(`${api_url}group/deleteTab`, {
      method: 'POST',
      body: JSON.stringify({
        tabId: tabId,
      })
    }, accessToken);

    return response.json();
  },
  //#endregion
  //#endregion
  //#endregion

  //#region Events
  //#region POST
  async insertEvent(
    title,
    event_tag,
    description,
    date_from,
    date_to,
    date_time,
    id_group,
    id_creator,
    admin,
    mainTabId,
    accessToken
  ) {
    const response = await helper.generateFetch(`${api_url}event`, {
      method: 'POST',
      body: JSON.stringify({
        title,
        event_tag,
        description,
        date_from,
        date_to,
        date_time,
        id_group,
        id_creator,
        mainTabId,
        admin
      })
    }, accessToken);

    return response.json();
  },

  async editEvent(eventId, newEvent, accessToken) {
    if (eventId > 0) {
      const response = await helper.generateFetch(`${api_url}event/editEvent/${eventId}`, {
        method: 'PUT',
        body: JSON.stringify(newEvent)
      }, accessToken);

      return response.json();
    }
  },

  async deleteEvent(eventId, accessToken) {
    if (eventId > 0) {
      const response = await helper.generateFetch(`${api_url}event/deleteEvent/${eventId}`, {
        method: 'DELETE'
      }, accessToken);

      return response.json();
    }
  },
  //#endregion

  //#region Get 
  async getSingleEvent(eventId) {
    const response = await helper.generateFetch(`${api_url}event/calendar/${encodeURIComponent(eventId)}`,{
      method: 'GET'
    }, null);

    return response.json();
  },

  async getUpcomingEvents(userId, tagIds, accessToken) {
    const response = await helper.generateFetch(`${api_url}event/upcomingEvents/${encodeURIComponent(userId)}`,{
        method: 'POST',
        body: JSON.stringify({ tagIds })
    }, accessToken);

    return response.json();
  },

  async getUpcomingEventsForTag(tagName, accessToken) {
    const response = await helper.generateFetch(`${api_url}event/${tagName}`,{
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  async getMonthData(year, month, id_user, accessToken) {
    const response = await helper.generateFetch(
      `${api_url}event/${encodeURIComponent(parseInt(year))}/${encodeURIComponent(parseInt(month))}/${encodeURIComponent(parseInt(id_user))}`,{
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  // Open api
  async getUpcomingEventsForTags(tags = []) {
    const response = await helper.generateFetch(`${api_url}event/open/tagEvents`, {
      method: 'POST',
      body : JSON.stringify({ tags: tags })
    })

    return response.json();
  },

  async logReferLinkVisitor(label = null) {
    await helper.generateFetch(`${api_url}refer-link`, {
      method: 'POST',
      body : JSON.stringify({ label })
    })
  },
  //#endregion
  //#endregion

  //#region App Posts
  //#region GET
  //#endregion

  //#region POST method
  async getFeed(ids, n, id, last_date, accessToken) {
    n = n === undefined ? 10 : parseInt(n);
    const response = await helper.generateFetch(`${api_url}post/feed`, {
      method: 'POST',
      body: JSON.stringify({
        ids: ids,
        n: n,
        last_date: last_date,
        id: id,
      })
    }, accessToken);

    return response.json();
  },

  async getFeedForGuest(ids, n, id, last_date) {
    n = n === undefined ? 10 : parseInt(n);
    const response = await helper.generateFetch(`${api_url}post/feed/open`, {
      method: 'POST',
      body: JSON.stringify({
        ids: ids,
        n: n,
        last_date: last_date,
        id: id,
      })
    });

    return response.json();
  },

  async newPost(model, userId, admin, group, groupId, tabId, adminOnly = false, accessToken) {
    admin = admin === true;
    group = group === true;
    groupId = parseInt(groupId);
    tabId = parseInt(tabId);

    const response = await helper.generateFetch(`${api_url}post/new`, {
      method: 'POST',
      body: JSON.stringify({
        userId: userId,
        text: model.text,
        img_url: model.img_url,
        tags: model.selectedTags,
        admin: admin,
        group: group,
        groupId: groupId,
        tabId: tabId,
        exposed: model.exposed,
        postType: model.postType,
        expirationDate: model.fullExpirationDate,
        preview: model.preview,
        adminOnly
      })
    }, accessToken);

    return response.json();
  },

    /**
   * Update the post data.
   * @param model
   * @returns {Promise<any>}
   */
    async updatePost(model, accessToken) {
      const response = await helper.generateFetch(`${api_url}post/update`, {
        method: 'POST',
        body: JSON.stringify(model)
      }, accessToken);

      return response.json();
    },
  

  async getIsolatedTagPosts(tagName, model, accessToken) {
    const response = await helper.generateFetch(`${api_url}post/tag/${tagName}`, {
      method: 'POST',
      body: JSON.stringify(model)
    }, accessToken);

    return response.json();
  },
  //#endregion
  //#endregion

  //#region Comments

  //#region GET
  async getComments(postId, accessToken) {
    const response = await helper.generateFetch(`${api_url}post/comments/${encodeURIComponent(postId)}`, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },
  //#endregion

  async insertComment(userId, postId, text, preview, postOwnerId = null, isGroupPost = null, groupId = null, accessToken) {
    const response = await helper.generateFetch(`${api_url}post/comments`, {
      method: 'POST',
      body: JSON.stringify({
        id_user: userId,
        id_post: postId,
        text,
        preview,
        postOwnerId,
        isGroupPost,
        groupId,
      })
    }, accessToken);

    return response.json();
  },
  //#endregion

  //#region Image upload
  async uploadImage(imgData, accessToken = null) {
    // img_data is a FormData object
    const response = await helper.generateFetch(`${api_url}image`, {
      method: 'POST',
      body: imgData
    }, accessToken, true);

    return response.json();
  },
  //#endregion

  //#region Tags
  async getTags() {
    const response = await helper.generateFetch(`${api_url}tag/tags`, {
      method: 'GET',
    })
    return response.json();
  },

  async getMonthDataForTag(tagName, year, month, id_user, accessToken) {
    const response = await helper.generateFetch(`
      ${api_url}event/${tagName}/${encodeURIComponent(parseInt(year))}/${encodeURIComponent(parseInt(month))}/${encodeURIComponent(parseInt(id_user))}`, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  async getTagSuggestions(accessToken) {
    const url = api_url + `tag/suggestions`
    const response = await helper.generateFetch(url, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  //#region Update
  async updateSubscription(userId, tagId, accessToken) {
    userId = parseInt(userId);
    tagId = parseInt(tagId);

    const response = await helper.generateFetch(`${api_url}user/subscriptions`, {
      method: 'POST',
      body: JSON.stringify({ userId, tagId })
    }, accessToken)

    return response.json();
  },
  //#endregion 
  //#region Patch
  async updateTagName(id, name, accessToken = null) {
    const response = await helper.generateFetch(`${api_url}tag/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ name })
    }, accessToken)
    return response.json();
  },
  //#endregion
  //#endregion

  //#region Admin
  async setAdminData(newData, accessToken) {
    const response = await helper.generateFetch(`${api_url}admin`, {
      method: 'POST',
      body: JSON.stringify({
        title: newData.title,
        img: newData.img,
      })
    }, accessToken);

    return response.json();
  },
  //#endregion

  //#region Stats
  async statisticsLogin(model, accessToken) {
    const response = await helper.generateFetch(`${api_url}user/statistics/login`, {
      method: 'POST',
      body: JSON.stringify({
        password: model.password,
      })
    }, accessToken);

    return response.json();
  },

  async getUserCount(accessToken) {
    const response = await helper.generateFetch(`${api_url}user/stats/userCount`, {
      method: 'GET',
    }, accessToken);

    return response.json();
  },

  async getReferLinkVisitLogs() {
    const response = await helper.generateFetch(`${api_url}refer-link`, {
      method: 'GET',
    });

    return response.json();
  },
  //#endregion

  //#region Notifications

  //#region GET
  async getNotifications(limit, accessToken) {
    const url = api_url + `notification?` + new URLSearchParams({ limit })

    const response = await helper.generateFetch(url, {
      method: 'GET'
    }, accessToken);

    return response.json();
  },

  //#endregion

  async cleanUserNotifications(accessToken) {
    const response = await helper.generateFetch(`${api_url}notification/seen-all`, {
      method: 'PATCH'
    }, accessToken);

    return response.json();
  },

  //#endregion
  

  async getAdminData() {
    const res = await fetch(api_url + "admin");
    return res.json();
  },

  async forgoten(model) {
    const res = await fetch(
      api_url + "user/forgoten-password",
      generateRequestOptions("POST", {
        email: model.email
      })
    );
    return res.json();
  },

  async allowReset(token) {
    const res = await fetch(
      api_url + "user/check-token",
      generateRequestOptions("POST", {
        token: token
      })
    );
    return res.json();
  },

  async resetPassword(password, hash) {
    const res = await fetch(
      api_url + "user/reset-password",
      generateRequestOptions("POST", {
        password: password,
        token: hash
      })
    );
    return res.json();
  },

  async getPinnedPosts() {
    const res = await fetch(
      api_url + "post/pinned",
      generateRequestOptions("GET")
    );
    return res.json();
  },
  /**
   * Get preview data if available.
   * @param message
   * @returns {Promise<any>}
   */
  async preview(message) {
    const res = await fetch(
      api_url + "post/preview",
      generateRequestOptions("POST", { text: message })
    );
    return res.json();
  },

  async getPostSelectedTags(postId) {
    const res = await fetch(
      api_url + "tag/tags/" + postId,
      generateRequestOptions("GET")
    );
    return res.json();
  },

  async softDeleteTag(tagId) {
    tagId = parseInt(tagId);

    const res = await fetch(
      api_url + "tag/delete",
      generateRequestOptions("POST", { id: tagId })
    );
    return res.json();
  },

  async getPost(postId) {
    const res = await fetch(
      api_url + "post/" + postId,
      generateRequestOptions("GET")
    );

    return res.json();
  },

  async getMonthDataForTagsOpen(tags, year, month) {
    const res = await fetch(
      api_url +
        `event/open/${encodeURIComponent(parseInt(year))}/${encodeURIComponent(
          parseInt(month)
        )}`,
      generateRequestOptions("POST", {
        user_tags: tags
      })
    );
    return res.json();
  },

  async getGroupEvents(groupId) {
    const res = await fetch(
      api_url + "group/groupEvents/" + encodeURIComponent(groupId),
      generateRequestOptions("GET")
    );

    return res.json();
  },

  async getUser(userID) {
    const res = await fetch(
      api_url + "user/user/" + encodeURIComponent(userID),
      generateRequestOptions("GET")
    );
    return res.json();
  },

  async updateBio(userId, bio) {
    const res = await fetch(
      api_url + "user/settings",
      generateRequestOptions("POST", { userId: userId, bio: bio })
    );
    return res.json();
  },

  async updateImg(userId, img_url) {
    const res = await fetch(
      api_url + "user/settings",
      generateRequestOptions("POST", { userId: userId, img_url: img_url })
    );
    return res.json();
  },

  async insertTag(name, userId) {
    const res = await fetch(
      api_url + "tag/",
      generateRequestOptions("POST", { id: userId, name: name })
    );
    return res.json();
  },

  notification: notification,
};
