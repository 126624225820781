<template>
  <div class="grid-container">
    <div class="logo">
      <img src="@/assets/icons/logo.png" />
    </div>
    <Login class="loginForm" />
  </div>
</template>

<script>
import Login from "@/components/Login";

export default {
  name: "SignIn",
  components: { Login },
}
</script>

<style lang="scss" scoped>
.grid-container {
  @include gradient;

  display: grid;
  grid-template-columns: 0.9fr 1.2fr 0.9fr;
  grid-template-rows: auto 15% auto 30%;
  gap: 0px 0px;
  grid-template-areas:
    ". kickstarter ."
    ". logo ."
    ". loginForm ."
    ". . .";

  @include center;
  text-align: center;
  height: 100vh;

  @include respond-to("medium") {
    grid-template-columns: 0.1fr auto 0.1fr;
    gap: 20px 20px;
  }
}

.kickstarterContainer {
  margin-top: 30px;
  background-color: #242326;
  width: 100%;
  max-width: 500px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  grid-area: kickstarter;
  @include center;

  img {
    margin-top: 20px;
    max-width: 90%;
  }

  h5 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .kickstarterText {
    color: #fff;
  }
}

.loginForm {
  grid-area: loginForm;
}
.logo {
  display: flex;
  align-items: flex-end;

  grid-area: logo;
  @include center;

  img {
    width: 200px;
    @include white-filter; // Hack, use proper logo instead of this?
  }
}
</style>
