export default {
	formatDateTime(d, includeTime=false) {
		// Return format:  DD.MM.YYYY
		if (typeof d === "number")
			d *= 1000; 		// From milliseconds to seconds

		d = new Date(d);
		let hours = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
		let minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
		let time = (includeTime) ? (" "+hours+":"+ minutes) : "";
		return d.getDate()+"."+(d.getMonth()+1)+"."+d.getFullYear() + time;
	},
	formatTimeSince(d) {
		const s = x => (parseInt(x) === 1) ? "" : "s";

		if (typeof d === "number")
			d *= 1000; 		// From milliseconds to seconds

		d = new Date(d);
		let seconds = Math.floor((new Date() - d) / 1000);
		let interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + " year" + s(interval) + " ago";
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + " month" + s(interval) + " ago";
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + " day" + s(interval) + " ago";
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + " hour" + s(interval) + " ago";
		}
		interval = seconds / 60;
		if (interval > 2) {
			return Math.floor(interval) + " minute" + s(interval) + " ago";
	}
		return "Just now";
	},

	formatProfileImg(url) {
		return (url !== false && url !== null && url !== undefined && url !== "null") ? url : require("@/assets/img/default.jpg");
	},
	formatGroupImg(url) {
		return (url !== false && url !== null && url !== undefined && url !== "null") ? url : require("@/assets/img/defaultGroup.jpg");
	},
	formatCoverImg(url) {
		return (url !== false && url !== null && url !== undefined && url !== "null") ? url : false;
	},

	replaceEmpty(obj) {
  		const replacer = (key, value) =>
    		String(value) === "null" || String(value) === "undefined" ? "" : value;

  		return JSON.parse(JSON.stringify(obj, replacer));
	}
};
