import { defineStore } from "pinia";

export const useUserTagStore = defineStore('tag', {
    state: () => (
        { 
            hasTags: []
        }
    ),
    getters: {

    },
    actions: {

    }
});