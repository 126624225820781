<template>
  <div class="cookie" :class="{hide: hide}">
    <div class="message">This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance.</div>
    <button class="accept-btn" @click="acceptNotice">Yes, I accept Cookies</button>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  data() {
    return {
      hide: false
    };
  },
  methods: {
    acceptNotice() {
      this.$store.setCookies();
      this.hide = !this.hide;
    }
  },
  mounted() {
    this.hide = this.$store.getCookies();
  }
}
</script>

<style scoped lang="scss">
.cookie {
  position: fixed;
  padding: 1em;
  bottom: 0;
  width: 100%;
  background: transparentize(#D67C1C, 0.05);
  display: flex;
  justify-content: space-evenly;
  color: #fff;
  align-items: center;
  .message {
    @include respond-to('small') {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .accept-btn {
    @include button(10px, true);
    box-shadow: 1px 1px 5px transparentize(#141416, 0.5);
  }

  &.hide {
    transform: translateY(100%);
  }
  transition: transform 0.3s;

  @include respond-to('medium') {
    width: revert;
  }
}
</style>
